
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function deleteCookie(cname) {
    const d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=;' + expires + ';path=/';
}

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function acceptCookieConsent() {
    deleteCookie('user_consent');
    setCookie('user_consent', 1, 30);
    document.getElementById('cookie-consent').classList.remove('visible');
}

cookie_consent = getCookie('user_consent');
if (cookie_consent != '') {
    document.getElementById('cookie-consent').classList.remove('visible');
} else {
    document.getElementById('cookie-consent').classList.add('visible');
}


document.getElementById('cookie-accept').addEventListener('click', acceptCookieConsent, true)